import { Controller } from "stimulus"
import Colcade from "colcade"

export default class extends Controller {
  initialize() {
    var grid = document.querySelector('.event-photos');
    var colc = new Colcade( grid, {
      columns: '.event-photos-grid-col',
      items: '.event-photos-grid-item'
    });
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

  }
  open_package_details_hide_button() {
    event.preventDefault()

    event.currentTarget.classList.toggle('is-hidden')
    if (event.currentTarget.nextElementSibling.classList.contains('package-content-details')) {
        event.currentTarget.nextElementSibling.classList.toggle('is-visible')
    }

  }
}
